import React, { useState } from 'react';
import style from './style.module.css';

const BibleSelector = ({newTestamentBooks,oldTestamentBooks,selectedBook,setSelectedBook, obterLivro}) => {

  // Função para lidar com a seleção do livro
  const handleBookSelection = (event) => {
    if(event.target.value!=""){
      // setSelectedBook(event.target.value);
      obterLivro(1,event.target.value)
    }else{ //caso seja value vazio, ele qr ultima marcacao
      obterLivro("","")
    }
    
  };

  return (
    <div className={style.bibleSelector}>
      <select
        id="bibleBooks"
        value={selectedBook}
        onChange={handleBookSelection}
        className={style.dropdown}
      >
        <option value="">Ultima marcação</option>

         {/* Separador para Velho Testamento */}
         <option disabled className={style.separator}>--- Velho Testamento ---</option>

        {/* Antigo Testamento */}
        {oldTestamentBooks.map((book, index) => (
          <option key={index} value={book}>
            {book}
          </option>
        ))}

        {/* Separador para Novo Testamento */}
        <option disabled className={style.separator}>--- Novo Testamento ---</option>

        {/* Novo Testamento */}
        {newTestamentBooks.map((book, index) => (
          <option key={index + oldTestamentBooks.length} value={book}>
            {book}
          </option>
        ))}
      </select>

      <div className={style.selectedBook}>
        {selectedBook && <p><strong>Livro Selecionado:</strong> {selectedBook}</p>}
      </div>
    </div>
  );
};

export default BibleSelector;

/*
******************DOCUMENTAÇÃO SIMPLES********************

O chats guarda todos os chats.
O chatSelecionado guarda um dos chats, que é o chat que foi clicado.

***************************************************CHATS

    "chats" tem essa estrutura, e vc acessa assim: chats.chats.id ou chats.user.uid (dentro de um map ou laço de repetição);
    "chatSelecionado" tambem, contudo, chatSelecionado tem apenas um dos chats, o selecionado, obviamente. Use chatSelecionado.user.uid, mesma coisa.

     chats:[{
        chat: {
            id: chat.id,
            participante_1_uid: chat.participante_1_uid,
            participante_2_uid: chat.participante_2_uid,
            data_criacao: chat.data_criacao,
            ultimo_envio: chat.ultimo_envio,
            ultimaMensagem: chat.ultimaMensagem, //Acredito q esse tb n tem
            ultimoSenderUid: chat.ultimoSender //Percebi q n tem mais esse
        },
        user: { //Esse é o outro usuário do chat, e nao o usuário logado
            uid: chat.uid,
            nome: chat.nome,
            sobrenome: chat.sobrenome,
            username: chat.username,
            profile_image: chat.profile_image
        },
        mensagem: [{
            remetenteUid: result.msg_remetenteUid,
            destinatarioUid: result.msg_destinatarioUid,
            conteudo: result.msg_conteudo,
            dataEnvio: result.msg_dataEnvio,
            status: result.msg_status
        },
          outros chats..  
        ]
    }]

*/


import MenuEsquerdo from "../MenuEsquerdo";
import style from "./style.module.css";
import React from "react";
import ShowUserLittleProfile from "../ShowUserLittleProfile";
import apiUrl from "../../global";
import { obterToken } from "../../global";
import { useState, useEffect,useRef } from "react";
import { AiOutlinePlus } from 'react-icons/ai'; // Ícone de '+'
import CreateNewChat from "./CreateNewChat";
import DescricaoComMais from "../DescricaoComMais/DescricaoComMais";
import { FaRegClock, FaCheck } from 'react-icons/fa';
import { FaCheckDouble } from 'react-icons/fa';
import bumpschat from "../../Imgs/bumpschat.png";
import { formatarTempo } from "../../global";
import threeDots from "../../Imgs/threeDots.png";
import { BiArrowBack } from "react-icons/bi";
const { DateTime } = require('luxon');




function Conversas({setSearchModalHandler,chatSelecionado,setChatSelecionado,user,chats, setChats, socket, janelaW, paragrafoMsgAntiga, setParagrafoMsgAntiga}) {
    const textareaRef = useRef(null);
    const [mensagem, setMensagem] = useState("");
    const exibirMensagensRef = useRef(null); //div onde tem o scroll das msgs, pra descer scroll ao clicar no chat
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(10); //Pois pega 10 msg de cara
    function marcarMensagemComoLida(chatId){ //Marca todas as msgs anteriores do chat atual como lida no redis e envia notificacao para a outra parte
        //Muda o status da mensagem no redis e tb envia pro outro usuario, enviando via websocket q vc já leu, pois vc já tá no chat então vc leu
         if (!chatSelecionado || !chatSelecionado.mensagem || chatSelecionado.mensagem.length == 0) {
            return;
        }
        const status = chatSelecionado.mensagem[chatSelecionado.mensagem.length-1]?.status
        const ultimoSenderUid = chatSelecionado.mensagem[chatSelecionado.mensagem.length-1]?.remetenteUid;
        if(!ultimoSenderUid || status=="lida" )return;
        if (socket && ultimoSenderUid != user.uid) {
            socket.emit('mensagemLida', {
                chatId: chatId,
                outraParte: chatSelecionado.user.uid //afinal o user só tem o outra parte
            });
         }

        //Agt muda tb nos useState chats e chatSelecionado q nós lemos a msg do outro user, pois mensagens de outra pessoa não lida
        //muda o background pra verde

        setChats(prevChats => {
            return prevChats.map(chat => {
                if (chat.chat.id === chatId) {
                    // Atualizando todas as mensagens deste chat
                    const updatedMensagens = chat.mensagem.map(msg => {
                        if (msg.destinatarioUid === user.uid && msg.status !== 'lida') {
                            return {
                                ...msg,
                                status: 'lida' // Atualizando o status para "lida"
                            };
                        }
                        return msg;
                    });

                    // Retorna o chat com as mensagens atualizadas
                    return {
                        ...chat,
                        mensagem: updatedMensagens
                    };
                }
                return chat;
            });
        });

        // Atualizando o chatSelecionado se o chatId for o mesmo
        if (chatSelecionado && chatSelecionado.chat.id === chatId) {
            setChatSelecionado(prevChatSelecionado => ({
                ...prevChatSelecionado,
                mensagem: prevChatSelecionado.mensagem.map(msg => {
                    if (msg.destinatarioUid === user.uid && msg.status !== 'lida') {
                        return {
                            ...msg,
                            status: 'lida' // Atualizando o status para "lida"
                        };
                    }
                    return msg;
                })
            }));
        }

    }

    const gerarIconeMensagem = (status) => {
        if (status === 'pendente') {
          return <FaRegClock style={{ color: 'orange' }} />;
        } else if (status === 'enviada') {
          return <FaCheck style={{ color: 'green' }} />;
        } else if (status === 'lida') {
            return <FaCheckDouble style={{ color: 'green' }} />;
        }
        return null;
    };

    useEffect(() => { //Colocando autoresize no textarea
        if (textareaRef.current) {
        const textarea = textareaRef.current;
        textarea.addEventListener('input', autoResize);

        return () => {
            textarea.removeEventListener('input', autoResize);
        };
        }
    }, []);

    //UseEffect pra pegar mensagem NOVAS do backend
    useEffect(() => {
        if (!socket) return;
    
        const handleMensagemRecebida = (novaMensagem) => {
            // Atualiza o estado de chats, adicionando a nova mensagem no chat correto e movendo o chat para o topo
            setChats((prevChats) => {
                const updatedChats = prevChats.map(chat => {
                    if (chat.chat.id === novaMensagem.chatId) {
                        // Se o chatId da nova mensagem corresponder ao chat atual, adiciona a mensagem
                        return {
                            ...chat,
                            mensagem: [...chat.mensagem, novaMensagem.mensagem] // Adiciona a nova mensagem ao array de mensagens
                        };
                    }
                    return chat; // Para os outros chats, mantém como está
                });
        
                // Move o chat com a nova mensagem para o topo da lista
                const chatComMensagem = updatedChats.find(chat => chat.chat.id === novaMensagem.chatId);
                const outrosChats = updatedChats.filter(chat => chat.chat.id !== novaMensagem.chatId);
                return [chatComMensagem, ...outrosChats]; // Coloca o chat com nova mensagem no início da lista
            });
        
            // Caso a msg é para o chat q ta selecionado, atualiza o chat selecionado tb
            if (chatSelecionado && chatSelecionado.chat?.id === novaMensagem.chatId) {
                setChatSelecionado((prevChatSelecionado) => ({
                    ...prevChatSelecionado,
                    mensagem: [...prevChatSelecionado.mensagem, novaMensagem.mensagem],
                }));
                //Muda o status da mensagem no redis e tb envia pro outro usuario, enviando via websocket q vc já leu, pois vc já tá no chat então vc leu
                marcarMensagemComoLida(novaMensagem.chatId);
            }
        };

        const handleMensagemLida = (dados) => {
            const { chatId, usuarioLeitor, msgIds } = dados;

            if(!msgIds){ //Se nao tem msgIds, sgnifica q vai atualizar o chat inteiro
                // Atualiza os chats locais
                setChats((prevChats) => {
                    return prevChats.map(chat => {
                        if (chat.chat.id === chatId) {
                            // Atualiza o status de todas as mensagens para 'lida'
                            const updatedMensagens = chat.mensagem.map(msg => ({
                                ...msg,
                                status: 'lida'
                                // ,usuarioLeitor
                            }));

                            return {
                                ...chat,
                                mensagem: updatedMensagens,
                            };
                        }
                        return chat; // Para outros chats, não altera
                    });
                });

                // Caso o chat selecionado seja o mesmo, também atualiza todas as mensagens no estado
                if (chatSelecionado && chatSelecionado.chat.id === chatId) {
                    setChatSelecionado((prevChatSelecionado) => ({
                        ...prevChatSelecionado,
                        mensagem: prevChatSelecionado.mensagem.map(msg => ({
                            ...msg,
                            status: 'lida',
                            usuarioLeitor,  // Marcar o usuário que leu a mensagem
                        })),
                    }));
                }
            }else{ //Atualizando só mensagens específicas q tem o id no msgIds
                 // Atualiza apenas as mensagens cujos IDs estão em msgIds
                setChats((prevChats) => {
                    return prevChats.map(chat => {
                        if (chat.chat.id === chatId) {
                            const updatedMensagens = chat.mensagem.map(msg =>
                                msgIds.includes(msg.id) // Verifica se o ID está na lista
                                    ? { ...msg, status: 'lida' } // Marca como lida
                                    : msg // Mantém como está
                            );

                            return {
                                ...chat,
                                mensagem: updatedMensagens,
                            };
                        }
                        return chat;
                    });
                });

                if (chatSelecionado && chatSelecionado.chat.id === chatId) {
                    setChatSelecionado((prevChatSelecionado) => ({
                        ...prevChatSelecionado,
                        mensagem: prevChatSelecionado.mensagem.map(msg =>
                            msgIds.includes(msg.id) // Verifica se o ID está na lista
                                ? { ...msg, status: 'lida', usuarioLeitor } // Marca como lida
                                : msg // Mantém como está
                        ),
                    }));
                }
            }

        };

        const handleConfirmacaoMensagemEnviada = (dados) => {
           const {chatId, idMensagem,dataEnvio} = dados;
            // Atualizar o status das mensagens em todos os chats
            setChats(prevChats => {
                return prevChats.map(chat => {
                    if (chat.chat.id === chatId) {  // Verificar se é o chat correto pelo ID do chat
                        const updatedMensagens = chat.mensagem.map(msg => {
                            if (msg.id === idMensagem) {  // Encontrar a mensagem pelo ID
                                return {
                                    ...msg,
                                    status: 'enviada',  // Atualiza o status para "enviada"
                                    dataEnvio:dataEnvio //Atualiza a data de envio com a nova data gerada no backend, pois em caso de latencia as msgs ainda estarão organizadas
                                };
                            }
                            return msg;
                        });
                        return { ...chat, mensagem: updatedMensagens };
                    }
                    return chat;
                });
            });
        
            // Atualizar também o chatSelecionado, caso ele seja o chat em questão
            if (chatSelecionado && chatSelecionado.chat.id === chatId) {
                setChatSelecionado(prevChatSelecionado => ({
                    ...prevChatSelecionado,
                    mensagem: prevChatSelecionado.mensagem.map(msg => {
                        if (msg.id === idMensagem) {  // Encontrar a mensagem pelo ID
                            return {
                                ...msg,
                                status: 'enviada',  // Atualiza o status para "enviada"
                                dataEnvio:dataEnvio
                            };
                        }
                        return msg;
                    })
                }));
            }
        };

    
        //Aqui você está recebendo mensagem de algum outro usuário
        socket.on('mensagemRecebida', handleMensagemRecebida);

        //Aqui é executado quando algum outro usuário leu sua mensagem
        socket.on('mensagemLidaConfirmada', handleMensagemLida);

        //Aqui sua mensagem chegou no backend, ou seja, marcar status como mensagem enviada
        socket.on('confirmacaoMensagemEnviada',handleConfirmacaoMensagemEnviada);
    
        return () => {
            if (socket) socket.off('mensagemRecebida', handleMensagemRecebida);
        };
    }, [socket, chatSelecionado]);

    //UseEffect para descer o scroll qd receber ou enviar msg
    useEffect(() => {
        if (exibirMensagensRef.current) {
            exibirMensagensRef.current.scrollTop = exibirMensagensRef.current.scrollHeight;
        }
    }, [chatSelecionado]);

    const autoResize = (event) => { //Espaçamento para textarea
        const textarea = event.target;
        textarea.style.height = '40px';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    async function enviarMensagem(e) {
        const idMensagem = crypto.randomUUID();

        const uidOutraParte = chatSelecionado.user.uid;
    
        if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey) {
            e.preventDefault(); 
    
            // Criando formatação para inserir no array de mensagem
            const novaMensagem = {
                id: idMensagem,
                remetenteUid: user.uid,
                destinatarioUid: uidOutraParte,
                conteudo: mensagem,
                dataEnvio: DateTime.utc().toFormat('yyyy-MM-dd HH:mm:ss.SSS'), // Agora inclui milissegundos
                status: "pendente"
            };
        
            // Atualizando mensagens localmente
            setChats(prevChats => {
                const updatedChats = prevChats.map(chat => {
                    if (chat.chat.id === chatSelecionado.chat?.id) {
                        // Se o chatId corresponder, adiciona a nova mensagem ao chat
                        return {
                            ...chat,
                            mensagem: [...chat.mensagem, novaMensagem], // Adiciona a nova mensagem ao array de mensagens
                            chatUltimoEnvio: mensagem // Atualiza a última mensagem do chat
                        };
                    }
                    return chat; // Mantém os outros chats inalterados
                });
    
                // Move o chat com a nova mensagem para o topo da lista
                const chatComMensagem = updatedChats.find(chat => chat.chat.id === chatSelecionado.chat.id);
                const outrosChats = updatedChats.filter(chat => chat.chat.id !== chatSelecionado.chat.id);
                return [chatComMensagem, ...outrosChats]; // Coloca o chat com a nova mensagem no início da lista
            });
    
            // Atualizando o chatSelecionado também
            setChatSelecionado(prevChatSelecionado => ({
                ...prevChatSelecionado,
                mensagem: [...prevChatSelecionado.mensagem, novaMensagem], // Adiciona a nova mensagem
                chatUltimoEnvio: mensagem // Atualiza a última mensagem
            }));
    
            setMensagem(""); // Limpando o input de mensagem
    
            // Verificando se o WebSocket está disponível e conectado
            if (socket && socket.connected) {
                // Enviando mensagem via WebSocket
                const mensagemSocket = { //dataEnvio é criado dnv no back por segurança, status lá vai pra enviada tb
                    idMensagem: idMensagem,
                    remetenteUid: user.uid,
                    destinatarioUid: uidOutraParte,
                    conteudo: mensagem,
                    chatId: chatSelecionado.chat.id,
                };
    
                socket.emit('enviarMensagem', mensagemSocket);
            } else {
                console.error('WebSocket não está conectado');
            }
        }
    }

    function exibirMensagens(mensagem,index){ 
        return(
            <span key={index}>
                {
                    mensagem.remetenteUid == user.uid && //Enviado pelo usuário logado
                    <div className={style.remetenteContainer}>
                        <div className={style.remetenteMensagem}>
                            <DescricaoComMais descricao={mensagem.conteudo} limite={200} blackColor={true}/>
                            <p className={style.dataEnvioCss}>{formatarTempo(mensagem.dataEnvio)}</p>
                            <div className={style.iconsSeen}>{gerarIconeMensagem(mensagem.status)}</div>
                        </div>
                            
                    </div>
                }
                 {
                    mensagem.remetenteUid !== user.uid && //Enviado pelo outro usuario
                    <div className={style.destinatarioContainer}>
                        <img src={chatSelecionado.user.profile_image} className={style.imgPerfil}/>
                        <div className={style.destinatarioMensagem}>
                            <p>{<DescricaoComMais descricao={mensagem.conteudo} limite={200} blackColor={true}/>}</p>
                        </div>
                    </div>
                }
            </span>
        )
    }

    function ExibirCabecalhoChat({chatSelecionado}){
        const [dropdownVisible, setDropdownVisible] = useState(false);

        // Função que alterna a visibilidade do dropdown
        const toggleDropdown = () => {
            setDropdownVisible(prevState => !prevState);
        };

        // Fecha o dropdown ao clicar fora
        const handleClickOutside = (event) => {
            if (!event.target.closest(`.${style.threeDots}`)) {
                setDropdownVisible(false);
            }
        };

        // Adiciona e remove o evento de clique no documento
        React.useEffect(() => {
            if (dropdownVisible) {
                document.addEventListener('mousedown', handleClickOutside);
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            }
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [dropdownVisible]);

        function onDeleteMsgs(){
            //Deleta de chats
            // Limpar apenas as mensagens do chat específico em `chats`
            setChats(prevChats =>
                prevChats.map(chat => {
                    if (chat.chat.id === chatSelecionado.chat.id) {
                        return {
                            ...chat,
                            mensagem: [] // Limpa apenas as mensagens do chat correspondente
                        };
                    }
                    return chat; // Retorna os outros chats inalterados
                })
            );

            //deleta de chat selecionado
            if (!socket || !chatSelecionado) return;
            setChatSelecionado(prevState => ({
                ...prevState,
                mensagem: [] // Limpar as mensagens do chat selecionado
            }));


            socket.emit('deletarMensagensChat', { chatId: chatSelecionado.chat.id });
            
        }

        const options = () => (
            <div className={style.dropdownMenu}>
                <ul>
                    <li onClick={()=>onDeleteMsgs()}>Apagar mensagens</li>
                </ul>
            </div>
        );
        return (
            <div className={style.flexCabecalho}> {/*Flex  row space-between*/}
                   <div className={style.flexRow}> {/*Flex row start*/}
                        {janelaW < 850 && (
                            <span className={style.seta} onClick={() => setChatSelecionado()}>
                                <BiArrowBack size={24} />
                            </span>
                        )}
                        <ShowUserLittleProfile user={chatSelecionado.user} />
                    </div>


                <span className={style.threeDots} onClick={toggleDropdown}>
                    <img src={threeDots} alt="Opções" />
                    {dropdownVisible && options()}
                </span>
            </div>
        );
    }

    async function handleClickMsgsAntigas(){
        const token = await obterToken();
        try {
        
            const response = await fetch(`${apiUrl}/obterMensagensAntigas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({
                   chatId: chatSelecionado.chat.id,
                   offset,
                   limit
                }),
            });

            if (!response.ok) {
                console.error("Algo deu errado");
                return;
            }

            setOffset(offset+limit);
            const result = await response.json() || [];
            const mensagensAntigas = result.mensagens || [];

            if(mensagensAntigas.length>0){
                // Adicionando as mensagens antigas no chatSelecionado
                setChatSelecionado(prevChatSelecionado => ({
                    ...prevChatSelecionado,
                    mensagem: [...mensagensAntigas, ...prevChatSelecionado.mensagem] // Insere as mensagens antigas no início
                }));

                // Atualizando os chats convencionais com as mensagens antigas
                setChats(prevChats => {
                    return prevChats.map(chat => {
                        if (chat.chat.id === chatSelecionado.chat.id) {
                            // Adicionando as mensagens antigas no chat correto
                            return {
                                ...chat,
                                mensagem: [...mensagensAntigas, ...chat.mensagem] // Insere as mensagens antigas no início
                            };
                        }
                        return chat;
                    });
                });
            }

            if(mensagensAntigas.length<limit){
                setParagrafoMsgAntiga(false);
            }

            
        } catch (error) {
            console.error('Erro ao buscar postagens:', error);
        }
    }

    return (
        <div className={style.conversas}>
            <div className={style.allMensagens} ref={exibirMensagensRef}> {/*Aqui que fica o overflow y */}
                    {
                        chats &&( //Já fez o load dos chats
                            chatSelecionado?(<> {/*Usuario clicou em um chat */}
                                <div className={style.chatCabecalho}>
                                    {<ExibirCabecalhoChat chatSelecionado={chatSelecionado}/>}
                                </div>
                                {chatSelecionado.mensagem?.length>0? //Tem mensagens pra esse chat selecionado
                                <div className={style.printMensagemContainer}>
                                    {paragrafoMsgAntiga &&
                                        <p className={style.mensagensAntigas} onClick={handleClickMsgsAntigas}>Mensagens antigas</p>
                                    }
                                    {chatSelecionado.mensagem.map((mensagem, index) => (
                                        exibirMensagens(mensagem,index)
                                    ))}
                                </div>
                                : //Nenhuma mensagem para esse chat, mas já  clicou no chat
                                <span className={style.semMensagens}>
                                    <br/><br/>
                                    <p className={style.noMessages}>Ainda não há mensagens. Envie a primeira para começar a conversa!</p>
                                </span>
                            }</>): //Tem chat disponível mas o usuário não clicou em nenhum
                            (  //Caso n tenha chatselecionado mas tenha chats disponiveis
                                
                                <div className={style.introMensagens}>
                                    <img src={bumpschat}/>
                                    <p>Envie uma mensagem para se conectar com amigos e fazer novas amizades!</p>
                                    <button onClick={()=>setSearchModalHandler(true)} className={style.novoChat}>Novo chat</button>
                                </div>
                                
                            )
                            //Caso nao tenha chats disponiveis
                        )
                    }
            </div>
            <span>
                { chatSelecionado&&
                    <textarea
                    value={mensagem}
                    onChange={(e)=>setMensagem(e.target.value)}
                    onKeyDown={enviarMensagem}
                    className={style.inputMensagem}
                    ref={textareaRef}
                    placeholder="Digite sua mensagem aqui.."
                    ></textarea>
                }
            </span>
        </div>
    );
}

export default function Index({user, setUser, socket}){
    const [chats, setChats] = useState([]); //Todos os chats/usuarios q tem msg com o usuario logado
    const [searchModalHandler, setSearchModalHandler] = useState(false); //Handler pra abrir modal de pesquisar usuario para new chat
    const [chatSelecionado, setChatSelecionado] = useState(); //Guarda um dos chats de "chats"
    const [janelaW, setjanelaW] = useState(window.innerWidth);
    const [forcarShortMobile, setForcarShortMobile] = useState({
        short: 1920, //Monitor grande já entra no short
        mobile: 650 //Simbolico q nunca irá atingir
    })
    const [chatOuMsg, setChatOuMsg] = useState("chat"); //Para tela < 850, exibe ou 'chat' ou 'mensagem'
    const [paragrafoMsgAntiga, setParagrafoMsgAntiga] = useState(true); //Handler se vai mostrar o paragrafo de "exibir mais msgs antigas"

    window.addEventListener('resize', () => { /*Listener de resize da janela pra mudar estilo de exibição */
        let larguraJanela = window.innerWidth;
        setjanelaW(larguraJanela);
    });


    useEffect(()=>{ //Alterna entre mensagem e chat
        if(chatSelecionado && chatSelecionado.chat.id){ //olho pelo id
            setChatOuMsg("mensagem");
        }else if(!chatSelecionado || !chatSelecionado.chat.id){
            setChatOuMsg("chat");
        }
    },[chatSelecionado])

    useEffect(() => { //Pegando chats, pega tb ultimas 10 msg do mysqll + todas do redis
        const fetchChats = async () => {
            try {
                const token = await obterToken();
                const response = await fetch(`${apiUrl}/obterChatsDisponiveis`, {
                    method: 'POST', // Ou 'POST' dependendo do seu endpoint
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`Erro: ${response.status}`);
                }
                const dataBanco  = await response.json();
                setChats(dataBanco.mensagens || []); // Ajuste de acordo com a estrutura de resposta esperada

                
            } catch (error) {
                console.error('Erro ao buscar mensagens:', error);
            }
        };

        fetchChats();
    }, []);

   
    function gerarUltimaMensagem(chat) { // É chamado ao carregar o chats e sempre q alterar algo no chats, em outras palavras, ao carregar/receber msg
        let quemEnviou;
    
        // Verificando se há mensagens no array e se a última mensagem tem um remetente
        if (!chat.mensagem || chat.mensagem?.length === 0 || !chat.mensagem[chat.mensagem.length - 1].remetenteUid) {
            return "";  //Retornar null = nao tem msg, entao automaticamente é exibido o username em vez da msg
        }
    
        // Pegando a última mensagem
        const ultimaMensagem = chat.mensagem[chat.mensagem.length - 1];
    
        // Verificando quem enviou a última mensagem
        if (ultimaMensagem.remetenteUid === user.uid) {
            quemEnviou = "Você: ";
        } else {
            quemEnviou = chat.user.nome + ": ";
        }
    
        // Retornando o remetente e o conteúdo da última mensagem
        return (quemEnviou + ultimaMensagem.conteudo);
    }


    function selecionarChat(chat){
        function marcarMensagemComoLida(chatId){ //Marca todas as msgs anteriores do chat atual como lida no redis e envia notificacao para a outra parte
            if(!chatId){
                return;
            }
            const status = chat.mensagem[chat.mensagem.length-1]?.status
            console.log("**status", status);
            const ultimoSender = chat.mensagem[chat.mensagem.length-1]?.remetenteUid;
            if(!ultimoSender || status=="lida")return;
            if (socket && ultimoSender != user.uid) { //Só envia se a ultima mensagem n for a sua
                socket.emit('mensagemLida', {
                    chatId: chatId,
                    outraParte: chat.user.uid //afinal o user só tem o outra parte
                });
            }
    
            //Agt muda tb nos useState chats e chatSelecionado q nós lemos a msg do outro user, pois mensagens de outra pessoa não lida
            //muda o background pra verde
            setChats(prevChats => {
                return prevChats.map(chat => {
                    if (chat.chat.id === chatId) {
                        // Atualizando todas as mensagens deste chat
                        const updatedMensagens = chat.mensagem.map(msg => {
                            if (msg.destinatarioUid === user.uid && msg.status !== 'lida') {
                                return {
                                    ...msg,
                                    status: 'lida' // Atualizando o status para "lida"
                                };
                            }
                            return msg;
                        });
    
                        // Retorna o chat com as mensagens atualizadas
                        return {
                            ...chat,
                            mensagem: updatedMensagens
                        };
                    }
                    return chat;
                });
            });
    
            // Atualizando o chatSelecionado se o chatId for o mesmo
            if (chatSelecionado && chatSelecionado.chat.id === chatId) {
                setChatSelecionado(prevChatSelecionado => ({
                    ...prevChatSelecionado,
                    mensagem: prevChatSelecionado.mensagem.map(msg => {
                        if (msg.destinatarioUid === user.uid && msg.status !== 'lida') {
                            return {
                                ...msg,
                                status: 'lida' // Atualizando o status para "lida"
                            };
                        }
                        return msg;
                    })
                }));
            }
    
        }
        const updatedChats = chats.map(c => //o chat clicado ganha selected true e os outros vão pra false
            c.user.uid === chat.user.uid ? { ...c, selected: true } : { ...c, selected: false }
        );
        setChats(updatedChats); // Atualiza o estado com os chats alterados
        setChatSelecionado(chat);

        //Se tiver chegando menos mensagem doq o padrão (10 msg) é pq tem menos que 10, daí não tem mensagens antigas e n exibe <p>
        chat.mensagem.length<10? setParagrafoMsgAntiga(false):setParagrafoMsgAntiga(true);
        
        //Garante q só vai ser chamado após atualzar os 2 estados acima
        setTimeout(() => { 
            marcarMensagemComoLida(chat.chat.id);
        }, 0);
            
    }

    const obterClasseChat = (chat, user) => {
        // Se o chat está selecionado, aplica a classe 'chatSelected'
        if(!chat.mensagem || chat.mensagem.length==0){
            return style.chatNotSelected;
        }
        if (chat.selected) {
            return style.chatSelected;
        }
    
        // Se o chat não está selecionado e a última mensagem é do outro usuário e tb não foi lida
        const ultimaMensagem = chat.mensagem[chat.mensagem.length - 1];
        if (ultimaMensagem && ultimaMensagem.remetenteUid && ultimaMensagem.remetenteUid !== user.uid && ultimaMensagem?.status !== 'lida') {
            return style.notReadMessage;
        }
    
        // Caso contrário, aplica 'chatNotSelected'
        return style.chatNotSelected;
    };

    function showChatList(){
        return(
            <div className={style.chatsExistentes}>
                    <div className={style.iconeNewChat} >
                        <AiOutlinePlus size={30} className={style.plusIcon} onClick={()=>setSearchModalHandler(true)}/>
                    </div>
                    {chats && chats.length > 0 ? (
                        chats.map(chat => (
                            chat.user ? (
                                <div className={obterClasseChat(chat,user)} onClick={() => selecionarChat(chat)} key={chat.user.uid}>
                                    <span className={style.relative}>
                                        <ShowUserLittleProfile user={chat.user} naoclicavel={true} mensagem={gerarUltimaMensagem(chat)} limiteCaracteres={23}/>
                                        {/*Pegando data da ultima msg*/}
                                        <p className={style.pAbsoluto}>
                                            {chat.mensagem && chat.mensagem.length > 0 ? 
                                                formatarTempo(chat.mensagem[chat.mensagem.length - 1].dataEnvio) : ""
                                            }
                                        </p>
                                    </span>
                                </div>
                            ) : null
                        ))
                    ) : (
                        <p className={style.inicieConversa}>Inicie uma nova conversa.</p>
                    )}
                </div>
        )
    }
    return (
        <React.Fragment>
          {
            searchModalHandler&&
            <CreateNewChat user={user} searchModalHandler={searchModalHandler} setSearchModalHandler={setSearchModalHandler} setChats={setChats} setChatSelecionado={setChatSelecionado}/>
          }
          <div className={style.container} >
            <div className={style.espaco_esquerdo}>
                {<MenuEsquerdo hideMobileMenuBottom={chatSelecionado?.chat?.id?true:false} user={user} setUser={setUser} forcarShortMobile={forcarShortMobile}/>}  {/*Esconde menu bottom se tiver algo no chat selecionado, isto é, se tiver exibindo msgs e n chat*/}
            </div> {/*Grid espaco-esquerdo*/}
            <div className={style.espaco_central}> {/*Grid espaco-central*/}
                {
                    janelaW>850? 
                        <>
                        {showChatList()}
                        <Conversas paragrafoMsgAntiga={paragrafoMsgAntiga} setParagrafoMsgAntiga={setParagrafoMsgAntiga} janelaW={janelaW} setSearchModalHandler={setSearchModalHandler} setChatSelecionado={setChatSelecionado} socket={socket} chats={chats} chatSelecionado={chatSelecionado} user={user} setChats={setChats}/>
                        </>
                    : //Caso seja <850, Menor q 850 é gerenciado por useState chatOuMsg
                    chatOuMsg=="mensagem"?
                        <Conversas paragrafoMsgAntiga={paragrafoMsgAntiga} setParagrafoMsgAntiga={setParagrafoMsgAntiga} janelaW={janelaW} setSearchModalHandler={setSearchModalHandler} setChatSelecionado={setChatSelecionado} socket={socket} chats={chats} chatSelecionado={chatSelecionado} user={user} setChats={setChats}/>
                    :chatOuMsg=="chat"&&
                        showChatList()
                }
            </div>
          </div>
          </React.Fragment>
    );
}
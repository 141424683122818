import React, { useState } from 'react';
import style from './index.module.css'; // Arquivo CSS para estilos
import ShowPostagens from './ShowPostagens';
import ShowUserLittleProfile from "./ShowUserLittleProfile";
import MenuEsquerdo from "./MenuEsquerdo";
import {fetchAndSetPostagens} from '../global';
import { useEffect, useRef } from 'react';
import loadingGif from "../Imgs/loadingIcon.gif"
import pix from "../Imgs/pix.png";
import passagens from '../Json/passagens.json';
function PassagemAleatoria() {
  const [passagemAleatoria, setPassagemAleatoria] = useState(null);

  useEffect(() => {
    const pegarPassagemAleatoria = () => {
      const indiceAleatorio = Math.floor(Math.random() * passagens.length);
      setPassagemAleatoria(passagens[indiceAleatorio]);
    };
    pegarPassagemAleatoria();
  }, []);

  return (
    <>
      {passagemAleatoria ? (
        <>
          {passagemAleatoria.livro} {passagemAleatoria.capitulo}:{passagemAleatoria.versiculo} - {passagemAleatoria.texto}
        </>
      ) : (
        <>Carregando...</>
      )}
    </>
  );
}

function Home({user, setUser, socket}) {
  const [offset, setOffset] = useState(0); //Offset pra load de postagens.
  const [limit, setLimit] = useState(8); //Limite de postagens loadadas por vez
  const [listaPostagens, setListaPostagens] = useState([]); //Guarda todas as postagens, assim como dados do postador.
  const [loadMore, setLoadMore] = useState(false); //Se true, é gatilho pra fazer load de postagem.
  const [loading, setLoading] = useState(true); //Se false quando já tiver fzd requisicao pra load de postagem, usado pra n fzer varias requisicoes
  const [atingiuFimPostagens, setAtingiuFimPostagens] = useState(false);//Fica true ao scrollar pro final da página
  const [errorLoadPost, setErrorLoadPost] = useState(""); //Exibe erro se não conseguir fazer load das postagens

  function ShowEspacoDireito() {
    return (
      <span >
        {user&&
          <div className={style.profileLittle}>
            <ShowUserLittleProfile user={user} limiteCaracteres={18}/>
          </div>
        }
        <div className={style.mundial}>
          <p className={style.title}>Do Brasil para o mundo 🌍</p>
          <p className={style.descricao}> 
            O custo para manter o Hevelim é alto devido ao seu tamanho. Ajude-nos a expandir para o mundo! Contribua com 1 real e faça a diferença ❤️ 
          </p>        
        </div>
          <span className={style.spanPix}>
            <img src={pix}/>
          </span>
      </span>
    );
  }

  useEffect(()=>{ //Load de postagens iniciais
    setLoadMore(true); //N pode inicializar como true na hr de criar, tem q mudar pra true pra fazer load! Se n da lenha.
  },[])

  useEffect(()=>{ //Load de postagens
    async function pegarDados(){
      try{
        let postagensNovas;
        postagensNovas = await fetchAndSetPostagens('',"everypost",offset,limit,user.uid);
        //console.log(postagensNovas);
        setListaPostagens(prevPostagens => [...prevPostagens, ...postagensNovas]);
        if (postagensNovas.length < limit) {
          setAtingiuFimPostagens(true); // Define que atingiu o fim se menos postagens forem retornadas
        }
        console.log(postagensNovas);
      } catch(error){
        setErrorLoadPost(`Ocorreu um erro ao carregar as postagens. Lamentamos pelo inconveniente e já estamos trabalhando para resolver o problema. Agradecemos muito sua compreensão.🙏`)
      }finally{
        setOffset(prevOffset => prevOffset + limit);
        setLoadMore(false);
      }
    }
    if(loadMore===true && user){
      pegarDados();
    }
  },[loadMore, user])

  const postagensRef = useRef(null);
  useEffect(() => { //Captar scroll
    const handleScroll = () => {
        if (postagensRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = postagensRef.current;
          if(atingiuFimPostagens){
            console.log("Fimm");
          }else{
            if (scrollTop + clientHeight >= scrollHeight - 100 && loading) { 
              console.log("vai pokemon");
              setLoadMore(true); //Ativa listener pra pegar mais posts
              setLoading(false); //Impede de chamar dnv
              setTimeout(() => { //Dps de 3 secs ativa a possibilidade de load dnv
                setLoading(true);
              }, 1000);
            } 
          }
          
        }
    };

    const div = postagensRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  },[loading, loadMore]); 

  const [scrollTop, setScrollTop] = useState(0); //Para guardar scroll
  const handleScroll = (event) => {
    setScrollTop(event.target.scrollTop); // Atualiza o estado com a posição do scroll
  };
  
  return (
    <React.Fragment>
      <div className={style.container} onScroll={handleScroll} ref={postagensRef}>
        <div className={style.espaco_esquerdo}>{<MenuEsquerdo socket={socket} user={user} setUser={setUser}/>}</div> {/*Grid espaco-esquerdo*/}
        <div className={style.espaco_central}> {/*Grid espaco-central*/}
          <div className={style.stories}></div>
          <div className={style.postagens}>
            <div className={style.passagens}>
              <p className={style.passagem}>
                <PassagemAleatoria/>
              </p>
            </div>
            {user&&<ShowPostagens listaPostagens={listaPostagens} setListaPostagens={setListaPostagens} user={user}/>}

            {!loading&&<div className={style.divGift}><img src={loadingGif} className={style.gifLoading}/></div>}
            
            {atingiuFimPostagens && (
              //Visualizou todas as postagens
              offset >0&& <p className={style.fimPostagens}>Nada mais por aqui. Que tal seguir algumas pessoas?</p>
            )}
            {errorLoadPost&&<p className={style.errorMessage}>{errorLoadPost}</p>}
          </div>
        </div>
          <div className={style.espaco_direito}> {/*Grid espaco-direito*/}
            {ShowEspacoDireito()}
          </div>
      </div>
      </React.Fragment>
  );
}

export default Home;

/*


********DOCUMENTAÇÃO RÁPIDA*********
Como o JSON tá organizado:

book{[ //variavel book
   [0]:{ //pagina 0 - variavel useState "pagina" guarda esse valor. Correção: agr n existe mais o "pagina", é usado o chapter-1 no lugar para simular a "pagina"
      [1]:{ //captulo 1 variavel useState "chapter" guarda esse valor
        [1]:{verso1 capitulo1}
        [2]:{verso2 captulo1}
        [3]:{verso3 captulo1}
        ...
      }
   [1]:{ //pagina 1
       [2]:{//captulo 2 
 	        [1]:{verso 1 captulo 2}
          [2]:{verso 2 captulo 2}
          [3]:{verso 3 captulo 2}
       }
   }
   ...
  }
]}

Lembrando que: o nome dos livros sempre em maiusculo, sem acento, e se for conjungo (1 Cronicas) sempre separar por espaço o numero do nome.
Esse é o padrão pra receber no backend

Observação2: Exibimos por captulos. A página tá pareada com os captulos, isto é, página1-1 = captulo1, mas ela (página) é inutil.
Na verdade só existe a variavel página pq organizaram errado o json, visto q ela (página) é totalmente dispensável.
Correção: agora usamos chapter-1 para simular a página
Como a página começa no 0 e o capitulo no 1, usamos -1 na hora do map, mas ambas são setadas com o msm valor pra facilitar
*/

import React, { useState, useEffect } from 'react';
import style from './index.module.css'; // Arquivo CSS para estilos
import MenuEsquerdo from "../../Home/MenuEsquerdo";
import apiUrl from '../../global';
import { obterToken } from '../../global';
import SelecionarLivro from "./SelecionarLivro";
import { MdOutlineBookmarkBorder,MdBookmark } from 'react-icons/md';
import Marcacoes from './MarcacoesModal';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'; // Importando o ícone da seta para baixo



const BibleViewer = ({user}) => {
  const oldTestamentBooks = [ //N mudar, está exatamente do jeito q tá no banco, isso evita consulta pra pegar o nome dos livros
    "Genesis", "Exodo", "Levitico", "Numeros", "Deuteronomio", "Josue", "Juizes", "Rute", "1 Samuel", "2 Samuel",
    "1 Reis", "2 Reis", "1 Cronicas", "2 Cronicas", "Esdras", "Nehemias", "Ester", "Jo", "Salmos", "Proverbios",
    "Eclesiastes", "Cantico dos Canticus", "Isaias", "Jeremias", "Lamentacoes", "Ezequiel", "Daniel", "Oseias", "Joel", "Amos",
    "Obadias", "Jonas", "Miqueias", "Naum", "Habacuque", "Sofonias", "Ageu", "Zacarias", "Malaquias"
  ];
  const newTestamentBooks = [ //N mudar, está exatamente do jeito q tá no banco, isso evita consulta pra pegar o nome dos livros
    "Mateus", "Marcos", "Lucas", "Joao", "Atos", "Romanos", "1 Corintios", "2 Corintios", "Galatas", "Efesios", 
    "Filipenses", "Colossenses", "1 Tessalonicenses", "2 Tessalonicenses", "1 Timoteo", "2 Timoteo", "Tito", "Filemom", 
    "Hebreus", "Tiago", "1 Pedro", "2 Pedro", "1 Joao", "2 Joao", "3 Joao", "Judas", "Apocalipse"
  ];
  const [book, setBook] = useState({});  //Acho q n serve pra nada mas no futuro vou usar pra guardar todos livros via cache no cliente
  const [chapter, setChapter] = useState(1); // Versículos para exibir, começa com 1 e não é decrementado na hr do map
  const [loading, setLoading] = useState(true); //Loading do book
  const [selectedBook, setSelectedBook] = useState(''); //Livro currente
  const [msgRecuperacao, setMsgRecuperacao] = useState(''); //Msg q aparece dps de recuperar o livro e some dps de alguma ação (next page ou mudar book)
  const [marcacoesSelectedBook, setMarcacoesSelectedBook] = useState(); //Marcacoes apenas do livro selecionado
  const [marcacoesHandler, setMarcacoesHandler] = useState(false); //Handler do modal de todas as marcacoes
  const [isLocketMarkChapter, setIsLocketMarkChapter] = useState(false); //Lock para n chamar funcao de markChapter duas vezes
  const [materiais, setMateriais] = useState(); //Guarda materiais (video audio) e os capitulos q deve ser exibido
  const [exibirVideo, setExibirVideo] = useState(false); //Variavel q controla se vai exibir ou n o video

  async function markOrUnmarkChapterAsRead(chapter,selectedBook){ //Marcar capitulo como lido ou desmarcar
    try { 
      if(isLocketMarkChapter) return;
      setIsLocketMarkChapter(true);
      
      /*** Atualize as marcações na hora para experiencia do usuario***/
      let userAction = '';
      const chapterAlreadyMarked = marcacoesSelectedBook.some( // Verificar se o capítulo já está marcado
        (marcacao) => marcacao.capitulo === chapter
      );

      if (chapterAlreadyMarked) { // Se já estiver marcado, desmarcar
        setMarcacoesSelectedBook((prevMarcacoes) =>
          prevMarcacoes.filter((marcacao) => marcacao.capitulo !== chapter)
        );
        userAction = 'desmarcar'; // A intenção foi desmarcar
      } else {// Se não estiver marcado, marcar
        setMarcacoesSelectedBook((prevMarcacoes) => [
          ...prevMarcacoes,
          { capitulo: chapter, dataMarcacao: '--' }
        ]);
        userAction = 'marcar'; // A intenção foi marcar
      }

      const token = await obterToken();
      const response = await fetch(`${apiUrl}/markBook`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          chapter,
          selectedBook,
          tipo: userAction //marcar ou desmarcar
        })
      });
      console.log("Response status:", response.status);
      const data = await response.json();
     
       
      if (!response.ok) { //Se n deu certo, vamos excluir a marcação visual do usuário
        setMarcacoesSelectedBook((prevMarcacoes) =>
          prevMarcacoes.filter((marcacao) => marcacao.capitulo !== chapter)
        );
      }

      return data; // Dados do post
    } catch (error) {
      console.log(error);
    }finally{
      setIsLocketMarkChapter(false);
    }
  }

  async function obterLivro(capitulo, livro) {
    /*
    ***Documentação***
    Ao entrar na nessa func pela primeira vez (via useEffect), esse código é executado sem haver o "capitulo" nem "livro"
    Quando a variável "book" chega vazia no backend, ou o backend pega o ultimo livro/capitulo marcado, ou, caso n tenha nada marcado,
    ele retorna o genesis no capitulo 1.

    Quando você troca de livro no componente SelecionarLivro, ele executa essa função passando o livro que ele quer e o capitulo como 1.

    Quando vc entra no objto MarcacoesModal e seleciona um livro com capitulo marcado, ele chama essa funcao passando tanto o livro
    quanto o capítulo que ele quer.

    Essa função não pode ficar dentro de useEffect pra executar sempre que mudar a variável selectedBook, pois não permitiria parâmetros.
    */
    try{
      const token = await obterToken();
      const resposta = await fetch(`${apiUrl}/getBook`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          book: livro || "",  //Envia parâmetro se tiver, caso seja primeiro load vai cair no ""
        }),
      });

      if (resposta.ok) {
        setMsgRecuperacao(''); //Limpa msg caso tenha
        const dados = await resposta.json();
        console.log(dados);
        setBook(dados.dados || [] );
        setMarcacoesSelectedBook(dados.marcacoes || []);
        setMateriais(dados.materiais || [])
        setSelectedBook(dados.nome); 
        if(capitulo){ //Se vc qr capitulo especifico, vamos usar o do parametro
          setChapter(capitulo);
        }else{ //caso contrário (caso seja primeiro load execuado via useEffects) 
          if(dados.marcacoes.length>0){ //Recuperou via marcação
            setMsgRecuperacao(`Vi que você marcou o capitulo ${dados.capitulo} como lido, então estou exibindo o capitulo ${dados.capitulo+1}. Aproveite a leitura :)`);
            setChapter(dados.capitulo+1); //Então é o próximo capitulo após o marcado
          }
          else{ //É o primeiro load, tanto faz usar dados.capitulo ou colocar 1 direto
            setChapter(dados.capitulo);
            setMsgRecuperacao("");
          }
        }
        setLoading(false);  // Finaliza o loading
      } else {
        console.error('Erro ao obter o livro:', resposta.statusText);
        setLoading(false);  // Finaliza o loading mesmo em caso de erro
      }
    }catch(error){
      console.log(error);
    }
  }

  useEffect(() => { //Load do livro inicial
    obterLivro();
  }, []);

  useEffect(()=>{
    console.log("Chapter: ",chapter)
  },[chapter])

  
  function ExibirVideo({ linkMaterial, setExibirVideo, exibirVideo }) {
    // Converte o link do YouTube para o formato embed
    const generateEmbedLink = (link) => {
      const url = new URL(link);
      const videoId = url.searchParams.get("v"); // Extrai o ID do vídeo
      // Retorna o link base no formato embed sem parâmetros de tempo
      return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
    };
    return (
      <div className={style.exibidorDeVideo}>
        <button
          className={style.botaoExibir}
          onClick={() => setExibirVideo(!exibirVideo)}
        >
          Exibir vídeo
          {exibirVideo ? <FaAngleDown /> : <FaAngleUp />}
        </button>
  
        {exibirVideo && (
          <div className={style.matVideoContainer}>
            <iframe
              className={style.matVideo}
              width="500px"
              height="600px"
              src={generateEmbedLink(linkMaterial)}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
    );
  }

  const ExplicacaoVersiculo = ({ titulo, corpo }) => {
    const [expandido, setExpandido] = useState(false); // Estado para controlar expansão
  
    const toggleExpansao = () => {
      setExpandido(!expandido);
    };
  
    return (
      <div className={style.explanation}>
        <p>
          <strong>{titulo}</strong>
          <button onClick={toggleExpansao} className={style.toggleButton}>
            {expandido ? 'Fechar' : 'Expandir'}
          </button>
        </p>
        {expandido && 
        <span>
            {user.pverificado?
              <p>{corpo}</p>
              :
              <div className={style.containerVerificarPerfil}>
                <span>Para acessar este conteúdo, é necessário completar o pagamento para verificar o perfil. Infelizmente, a plataforma depende dessa contribuição para continuar oferecendo serviços de qualidade.</span>
                <br/><span>Vantagens de um perfil verificado:</span>
                <p> Acesso aos estudos bíblicos</p>
                <p> Ganhe o selo de verificado</p>
                <p> Melhore a credibilidade do seu perfil</p>
                <p> Aumente o alcance das suas postagens</p>
                <p> Aumente o limite do tamanho dos vídeos</p>
                <p> Aumente a duração dos vídeos enviados</p>
                <p> Contribua para o crescimento da plataforma e, dessa forma, estará contribuindo para espalharmos o evangelho. Nossa meta é que a plataforma saia do Brasil para o mundo!</p>
                <span>Acesse seu perfil, clique em Editar e vá em Verificação de perfil.</span>
              </div>
            }
        </span>

        } {/* Mostrar o corpo da explicação apenas se expandido */}
      </div>
    );
  };
  return(
    <div>
    
      {/*Ultima marcação*/}
      {
        marcacoesHandler &&
          <Marcacoes markOrUnmarkChapterAsRead={markOrUnmarkChapterAsRead} setMarcacoesHandler={setMarcacoesHandler} obterLivro={obterLivro}/>
      }
      
      {/*Selecionar o livro que quiser*/}
      <SelecionarLivro obterLivro={obterLivro} oldTestamentBooks={oldTestamentBooks} newTestamentBooks={newTestamentBooks} selectedBook={selectedBook} setSelectedBook={setSelectedBook}/>
      


      {/*Msg de marcação recuperada*/}
      {msgRecuperacao&&
        <p className={style.recuperacao}>{msgRecuperacao}</p>
      }

      {/*Título da página*/}
      <p className={style.captulo}>{selectedBook + " - Capitulo " + chapter }</p>
      
      {/*Exibe componente marcacoes e materiais */}
      <div className={style.exibicoes}>
        {/* Exibir materiais do capítulo atual */}
        
        {materiais && (
          <div className={style.materiais}>
            {materiais
              .filter((material) => material.capitulo === chapter)
              .map((material, index) => (
                <div key={index} className={style.materialItem}>
                  {material.linkMaterial && (
                    // Exibir vídeo
                    <ExibirVideo linkMaterial={material.linkMaterial.trim()} exibirVideo={exibirVideo} setExibirVideo={setExibirVideo} /> // Passando o link do material
                  ) }
                </div>
              ))}
          </div>
        )}


        {/*Botão exibir marcações */}
        { //Exibe marcação se n tiver exibindo o vídeo
          !exibirVideo && <button className={style.botaoExibir} onClick={()=>setMarcacoesHandler(true)}>Exibir marcações</button>
        }
      </div>

      { //Caso esteja exibindo o vídeo acima, vai exibir a marcação aqui, fora do div, em baixo do vídeo
          exibirVideo && <button className={style.botaoExibir} onClick={()=>setMarcacoesHandler(true)}>Exibir marcações</button>
      }


      {/*Livro em si */}
      <span className={style.corpoBiblia}>
      {book?.[chapter - 1]?.[chapter] &&
        Object.entries(book[chapter - 1][chapter])
          .filter(([key]) => !key.includes("_")) // Filtra apenas os versículos numerados, isto é, sem as explicacoes
          .map(([key, value]) => (
            <span key={key}>
              <div className={style.verse}>
                <p className={style.spanVerse}>{key}</p>
                <p>{value}</p>
              </div>

              {/* Renderiza explicação se existir */}
              {book[chapter - 1][chapter]?.[`${key}_titulo`] && (
                <ExplicacaoVersiculo
                  titulo={book[chapter - 1][chapter][`${key}_titulo`]}
                  corpo={book[chapter - 1][chapter][`${key}_corpo`]}
                  style={style}
                />
              )}
            </span>
        ))}
      </span>

      {/*Marcar livro*/}
      <span className={style.marcarLido} onClick={()=>markOrUnmarkChapterAsRead(chapter,selectedBook)}>
        
        {
          marcacoesSelectedBook?.some((marcacao) => marcacao.capitulo === chapter) ? (
            <>
            <p>Lido!</p>
            <MdBookmark size={30} />
            </>
          ) : (
            <>
              <p>Marcar como lido:</p>
              <MdOutlineBookmarkBorder size={30} />
            </>
          )
        }
      </span>

      {/* <MdBookmark size={30} /> */}

      {/* Exibir botões de capítulos com navegação e input */}
      {book && (
        <div className={style.chapterButtonsContainer}>
          {/* Botão para voltar */}
          <button
            className={style.chapterButton}
            onClick={(e) => {
              if (chapter > 1) {
                setChapter(chapter - 1);
              }
              if(msgRecuperacao)setMsgRecuperacao("");
            }}
            disabled={chapter <= 1}
          >
             {chapter > 1 ? selectedBook + " " + (chapter - 1) : "Inicio"}

          </button>

          {/* Input para digitar o capítulo */}
          <input
            type="number"
            value={chapter}
            onFocus={(e) => e.target.select()} // Seleciona todo o valor ao focar no input
            onChange={(e) => { 
              const maximo = Object.entries(book).length;
              if(e.target.value>maximo){
                setChapter(maximo);
                setChapter(parseInt(maximo));
              }else if (e.target.value<1){
                setChapter(1);
                setChapter(parseInt(1));
              }else{
                setChapter(parseInt(e.target.value)); 
              }
            }}
            className={style.chapterInput}
            min="1"
            max={Object.entries(book).length}
          />

          {/* Botão para avançar */}
          <button
            className={style.chapterButton}
            onClick={() => {
              if (chapter < Object.entries(book).length) {
                setChapter(chapter + 1);
              }
              if(msgRecuperacao)setMsgRecuperacao("");
            }}
            disabled={chapter >= Object.keys(book).length}
          >
              {chapter < Object.entries(book).length ? `${selectedBook} ${chapter + 1}` : "FIM"}
          </button>
        </div>
      )}

    </div>
  )
};


function Home({user, setUser, socket}) {

  function ShowEspacoDireito() {
    return (
      <span >
        
      </span>
    );
  }


  return (
    <React.Fragment>
      <div className={style.container}>
        <div className={style.espaco_esquerdo}>{<MenuEsquerdo socket={socket} user={user} setUser={setUser}/>}</div> {/*Grid espaco-esquerdo*/}
        <div className={style.espaco_central}> {/*Grid espaco-central*/}
          <div className={style.postagens}>
          <BibleViewer user={user}/>
          </div>
        </div>
        <div className={style.espaco_direito}> {/*Grid espaco-direito*/}
          {ShowEspacoDireito()}
        </div>
      </div>
      </React.Fragment>
  );
}

export default Home;

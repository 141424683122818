import style from "./style.module.css";
import { useState, useEffect } from "react";
import { FaArrowLeft } from 'react-icons/fa';
import apiUrl from "../../../global";
import { obterToken } from "../../../global";

export default function Marcacoes({ setMarcacoesHandler, obterLivro, markOrUnmarkChapterAsRead }) {
    const [expanded, setExpanded] = useState(null);
    const [marcacoes, setMarcacoes] = useState([]);

    function selecionarMarcacao(livro,capitulo){
        obterLivro(capitulo,livro);
        setMarcacoesHandler(false);
    }
    function handleDeleteMarcacao(livro,capitulo){

      //Deleta no banco de dados
      markOrUnmarkChapterAsRead(capitulo,livro);

      // Atualiza a lista de marcações no estado
      setMarcacoes((prevMarcacoes) =>
        prevMarcacoes.filter(
          (marcacao) => !(marcacao.bookName === livro && marcacao.capitulo === capitulo)
        )
      );
    }
    
  
    useEffect(() => {
      async function obterMarcacoes() {
        try {
          const token = await obterToken(); // Função para obter o token do usuário
          const response = await fetch(`${apiUrl}/obterTodasMarcacoes`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            console.error('Erro ao obter marcações:', await response.json());
            return;
          }
  
          const data = await response.json();
          console.log(data.marcacoes);
          setMarcacoes(data.marcacoes); // Atualiza o estado com as marcações recebidas
        } catch (error) {
          console.error('Erro ao buscar marcações:', error);
        }
      }
  
      obterMarcacoes();
    }, []);
  
    return (
      <>
        <div
          className={style.fadeBackground}
          onClick={() => setMarcacoesHandler(false)}
        ></div>
        <div className={style.marcacao}>
          <span className={style.voltarContainer}>
            <FaArrowLeft
              className={style.setaVoltar}
              onClick={() => setMarcacoesHandler(false)}
            />
            <p>Últimas Marcações</p>
            <span/>
          </span>
  
          <div className={style.marcacoesContainer}>
            {/* Renderiza as marcações recebidas */}
            {marcacoes.length > 0 ? (
              marcacoes.map((marcacao, index) => (
                <div className={style.marcacaoItem} key={index}>
                  <div className={style.marcacaoInfo}>
                    <p>
                      <strong>Livro:</strong> {marcacao.bookName}
                    </p>
                    <p>
                      <strong>Capítulo:</strong> {marcacao.capitulo}
                    </p>
                  </div>
                  <div className={style.marcacaoActions}>
                    <button className={style.acessarBtn} onClick={()=>selecionarMarcacao(marcacao.bookName,marcacao.capitulo)}>Acessar</button>
                    <button className={style.deleteBtn} onClick={()=>handleDeleteMarcacao(marcacao.bookName, marcacao.capitulo)}> Deletar </button>
                  </div>
                  {expanded === index && (
                    <div className={style.marcacaoDetails}>
                      <p>
                        <strong>Detalhes adicionais:</strong>
                      </p>
                      <p>{marcacao.detalhes || 'Sem informações adicionais'}</p>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className={style.semMarcacoes}>
                Nenhuma marcação encontrada.
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
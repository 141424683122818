import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage} from "firebase/storage";
import { getAuth} from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyBdWTikL7uy4dTENzVVKdRNBcQrb082E1Y",
    authDomain: "hevelim-a92d1.firebaseapp.com",
    projectId: "hevelim-a92d1",
    storageBucket: "hevelim-a92d1.firebasestorage.app",
    messagingSenderId: "463946788272",
    appId: "1:463946788272:web:f8ce45805ca285ea0bfa75",
    measurementId: "G-5DNWM2Z571"
  };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);



  


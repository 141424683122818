import style from "./style.module.css";
import React from "react";
import ShowUserLittleProfile from "../../ShowUserLittleProfile";
import { useState, useEffect } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import { getUser } from "../../../global";
import { obterToken } from "../../../global";
import apiUrl from "../../../global";
export default function CreateNewChat({user,searchModalHandler,setSearchModalHandler, setChats, setChatSelecionado}){
    const [searchInput, setSearchInput] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false); // Para controlar o estado de carregamento
    
    const searchUsers = async () => {
        if (!searchInput.trim()) {
        setResults([]);
        return;
        }
    
        setResults([]); //Tira do null pq fez pesquisa nova
        setLoading(true); // Inicia o carregamento
    
        try {
        const result = await getUser("search",searchInput);
        if(result){
            // Chega como obj, converter pra array
            const usuariosArray = Object.values(result)
            .filter(item => typeof item === 'object' && item.uid); // Filtra apenas os objetos de usuários, ignora o claim

            setResults(usuariosArray); 
        }else{ //N tem nada, colocar o useState como null pra aparecer q n tem resultado
            setResults(null);
        }
        } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        } finally {
        setLoading(false); // Finaliza o carregamento
        }
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
        searchUsers();
        }
    };

    useEffect(() => { //Ao voltar no celular, fecha o modal em vez de ir pra página anterior
        
        window.history.pushState(null, '', window.location.href);// Adiciona uma entrada ao histórico quando o modal é aberto  
        
        const handlePopState = () => {
        setSearchModalHandler(false);
        };
    
        window.addEventListener('popstate', handlePopState);
    
        // Limpeza do listener ao desmontar o modal
        return () => {
        window.removeEventListener('popstate', handlePopState);
        };
    }, [searchModalHandler]); //Handler como dependencia (pois é ao abrir e ao fechar modal)

   async function adicionarNaLista(outrouser) {
    let chatId;
        try {
            const token = await obterToken();
            const response = await fetch(`${apiUrl}/criarChatVazio`, {
                method: 'POST', // Ou 'POST' dependendo do seu endpoint
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userIdDestinatario: outrouser.uid
                })
            });

            if (!response.ok) {
                throw new Error(`Erro: ${response.status}`);
            }
            const data = await response.json();
            chatId = data.chatId;
        } catch (error) {
            console.error('Erro ao buscar mensagens:', error);
        }

        setChats(prevState => {
            // Verifica se o usuário já está na lista
            const usuarioExistente = prevState.find(chat => chat.user?.uid === outrouser.uid);
            
            if (usuarioExistente) {
                // Atualiza todos os chats existentes para "selected: false"
                const updatedChats = prevState.map(chat => ({
                    ...chat,
                    selected: false
                }));
        
                // Move o chat do usuário existente para o topo e define como selecionado
                const chatsSemUsuario = updatedChats.filter(chat => chat.user?.uid !== outrouser.uid);
                const usuarioAtualizado = { 
                    ...usuarioExistente, 
                    selected: true 
                };
        
                // Define o chat selecionado
                setChatSelecionado(usuarioAtualizado);
        
                return [usuarioAtualizado, ...chatsSemUsuario];
            }
        
            // Se o usuário não existir na lista, adiciona um novo chat
            const novoChat = { 
                chat: {
                    id: chatId,
                    participante_1_uid: user.uid, // O usuário logado
                    participante_2_uid: outrouser.uid, // O outro usuário
                }, 
                user: outrouser, 
                mensagem: [], // Não remover isso
                selected: true 
            };
        
            // Define o novo chat como selecionado
            setChatSelecionado(novoChat);
        
            // Atualiza todos os chats existentes para "selected: false", e adiciona o novo chat no início
            const updatedChats = prevState.map(chat => ({
                ...chat,
                selected: false
            }));
        
            return [novoChat, ...updatedChats]; // Adiciona o novo usuário com chat null ao início da lista
        });
        
        
    
        setSearchModalHandler(false);
    }
    
    return (
        <span>
            <div className={style.fadeBackground} onClick={()=>setSearchModalHandler(false)}></div>
            <div className={style.searchModal}>
                <span className={style.iconClose}>
                    <AiOutlineClose size={24} onClick={()=>setSearchModalHandler(false)}/>
                </span>
                <p className={style.searchModalTitle}>Novo chat</p>
                <span className={style.searchModalContainer}>
                    <input
                    className={style.searchModalInput}
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="Nome ou username"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <button className={style.searchModalButton} onClick={searchUsers}>Procurar</button>
                </span>
                <div className={style.results}>
                    {loading && <p>Carregando...</p>} {/* Mensagem de carregamento */}
                    {results && results.length > 0 && results.map((user, index) => (
                    <span key={index} onClick={()=>adicionarNaLista(user)}>
                        <ShowUserLittleProfile user={user} naoclicavel={true}/>
                    </span>
                    ))}
                    {results===null && !loading && <p>Nenhum usuário encontrado</p>}
                </div>
            </div>
        </span>
    );
}
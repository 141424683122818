import { getAuth, signOut  } from "firebase/auth";
import { DateTime } from 'luxon';




/*export function calcularTempoDecorrido(dataPostagemISO) { //Versão Iso
  const tempoAtual = new Date();
  const dataPostagemDate = new Date(dataPostagemISO); // Convertendo a string ISO para Date

  const diferenca = tempoAtual - dataPostagemDate;

  const segundos = Math.floor(diferenca / 1000);
  const minutos = Math.floor(segundos / 60);
  const horas = Math.floor(minutos / 60);
  const dias = Math.floor(horas / 24);
  const semanas = Math.floor(dias / 7);
  const meses = Math.floor(dias / 30);
  const anos = Math.floor(dias / 365);

  if (anos > 0) {
      return `há ${anos} a`;
  } else if (meses > 0) {
      return `há ${meses} m`;
  } else if (semanas > 0) {
      return `há ${semanas} sem`;
  } else if (dias > 0) {
      return `há ${dias} d`;
  } else if (horas > 0) {
      return `há ${horas} h`;
  } else if (minutos > 0) {
      return `há ${minutos} min`;
  } else {
      return `agora`;
  }
}*/



const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000'; //Favor n alterar senao causa erro no menuLateral
export default apiUrl;

export function capitalizeFirstLetter(string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const handleShare = (title, url) => {
  const baseSite = window.location.hostname;  // Isso vai capturar "hevelim.com" automaticamente ou entaa localhost 

  const urlFinal = `https://${baseSite}/?p=${url}`; // Construindo a URL final....

    if (navigator.share) {
        navigator.share({
            title: title,
            url: urlFinal 
        })
        .then(() => console.log('Compartilhado com sucesso!'))
        .catch((error) => console.log('Erro ao compartilhar:', error));
    } else {
        // Fallback para navegadores que não suportam a API de compartilhamento
        alert(`Copie o link: ${url}`);
    }
};
export function calcularTempoDecorrido(dataPostagemUTC) { //Chega data no formato do mysql YYYY-MM-DD HH:MM:SS exp (2024-11-15 16:28:44)
  /*
  No heroku funciona pois ta em UTC, no localhost aparece 3 hrs adiantado, normal.
  */
  const tempoAtual = new Date(); // Tempo atual em UTC
  const dataPostagemDate = new Date(dataPostagemUTC).getTime(); // Converte a data da postagem para milissegundos
  const diferenca = tempoAtual.getTime() - dataPostagemDate; // Calcula a diferença em milissegundos
 
  

  // Calcula o tempo decorrido em diferentes unidades
  const segundos = Math.floor(diferenca / 1000);
  const minutos = Math.floor(segundos / 60);
  const horas = Math.floor(minutos / 60);
  const dias = Math.floor(horas / 24);
  const semanas = Math.floor(dias / 7);
  const meses = Math.floor(dias / 30);
  const anos = Math.floor(dias / 365);

  // Retorna o tempo decorrido em um formato legível
  if (anos > 0) {
      return ` ${anos} a`;
  } else if (meses > 0) {
      return ` ${meses} m`;
  } else if (semanas > 0) {
      return ` ${semanas} sem`;
  } else if (dias > 0) {
      return ` ${dias} d`;
  } else if (horas > 0) {
      return ` ${horas} h`;
  } else if (minutos > 0) {
      return ` ${minutos} min`;
  } else {
      return `agora`;
  }
}
export function formatarTempo(dataPostagemMySQL) { //Data sempre chega em formato mysql
  try {

    const dataPostagemLuxon = DateTime.fromSQL(dataPostagemMySQL, { zone: 'utc' }).setZone('local'); //Converte de utc pra local

    if (!dataPostagemLuxon.isValid) {
      return "..."; // Indica erro, exibe isso em vez da data para o usuário
    }

    // Tempo atual no fuso local
    const tempoAtual = DateTime.local();

    // Diferença entre a data da postagem e o tempo atual em segundos
    const diferencaEmSegundos = tempoAtual.diff(dataPostagemLuxon, 'seconds').seconds;

    // Verifica se a diferença é muito pequena para considerar "agora"
    if (Math.abs(diferencaEmSegundos) < 1) {
      return "agora";
    }

    // Data no futuro
    if (diferencaEmSegundos < 0) {
      return "..?"; // Indica que a data está no futuro
    }

    // Formata o intervalo relativo
    if (diferencaEmSegundos < 60) {
      return "agora";
    } else if (diferencaEmSegundos < 3600) { // Menos de 1 hora
      const minutos = Math.floor(diferencaEmSegundos / 60);
      return `${minutos}m`;
    } else if (diferencaEmSegundos < 86400) { // Menos de 24 horas
      return dataPostagemLuxon.toFormat('HH:mm'); // Formato HH:mm
    } else if (diferencaEmSegundos < 2592000) { // Menos de 30 dias
      const dias = Math.floor(diferencaEmSegundos / 86400);
      return `${dias}d`;
    } else if (diferencaEmSegundos < 31536000) { // Menos de 1 ano
      const meses = Math.floor(diferencaEmSegundos / 2592000);
      return `${meses}m`;
    } else {
      const anos = Math.floor(diferencaEmSegundos / 31536000);
      return `${anos}a`;
    }
  } catch (error) {
    console.error("Erro ao formatar a data:", error);
    return "..."; // Indica erro ao formatar data
  }
}
export function bloquearScroll(bloquear) {
    if (bloquear) {
        // Bloquear scroll
        let larguraBarra = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.overflow = 'hidden';
        document.body.style.marginRight = `${larguraBarra}px`; // Adiciona margem à direita para compensar a barra de rolagem oculta
    } else {
        // Desbloquear scroll
        document.body.style.overflow = 'auto';
        document.body.style.marginRight = '0'; // Remove a margem adicionada para a barra de rolagem
    }
}
export function handleLogout(navigate) {
  const auth = getAuth();


  signOut(auth)
    .then(() => {
      console.log('Usuário deslogado com sucesso.');
      if(navigate){
      navigate('/'); // Usar o navigate para redirecionar
      }
      //window.location.replace('/'); 
    })
    .catch((error) => {
      console.error('Erro ao deslogar:', error);
    });
};
export async function getUser(tipo,username) {
  /*
  Tipo: 'pessoal' ou 'terceiro' ou 'Search'
  Funcionamento do tipo 'pessoal': Espera só o Tipo, n precisa do 'username'. Pega todos dados do usuario ao confirmar token.
  Funcionamento "terceiro": Espera Tipo e Username, pega dados básicos q n comprometem nada, como nome, sobrenome etc. 
  Functionamento 'search': Espera Tipo e, no lugar do username, pode ser nome, nome+sobrenome, sobrenome ou username.
  Tipo search: não pesquisa usuário logado
  */
  try {
    // Buscar dados do usuário usando o uid (extraído do token, não o do parâmetro) e o email
    const idTokenResult = await obterTokenComClaims();
    const token = idTokenResult.token;
    const claims = idTokenResult.claims; // Acessa as claims
    
    const resposta = await fetch(`${apiUrl}/getUsuario`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({tipo,username}) 
    });

    if (resposta.ok && resposta.status !== 204) {
      const dadosUsuario = await resposta.json();
      console.log("*************",dadosUsuario);
      return { ...dadosUsuario, claims };
    } else if(resposta.status==204){
      console.log('Usuario nao encontrado no banco.');
      return null;
    }
  } catch (error) {
    console.error('Erro ao buscar dados do banco:', error);
    return null;
  }
}
export async function pegarDadosPostagemUnica(postId) {
  try { 
    
    const token = await obterToken();
    const response = await fetch(`${apiUrl}/getOnePostData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ postId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados do post
  } catch (error) {
    // Se desejar tratar erros de forma personalizada
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function getSeguidores(userId, tipo, limit, offset, search) {
    /*
    userId -> Id do usuario q vc qr pesquisar
    tipo -> ativo(quem esse usuario segue) passivo (quem segue esse usuario)
    offset e limit -> dispensa apresentações
    search -> é uma string com uma palavra, significa que vc quer pesquisar algum usuario q tem relacao com o userId (segue ou é seguido).
    Essa relação obviamente é denominada TAMBÉM pelo tipo (ativo ou passivo).
    */
  try { 
    const token = await obterToken();
    const response = await fetch(`${apiUrl}/getSeguidores`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ userId, tipo, limit, offset,search })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados do post
  } catch (error) {
    // Se desejar tratar erros de forma personalizada
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function obterToken() {
  const auth = getAuth(); // Obtém a instância de autenticação
  const user = auth.currentUser; // Obtém o usuário autenticado atual

  if (user) {
    try {
      const token = await user.getIdToken(); // Obtém o token do usuário
      return token;
    } catch (error) {
      console.error('Erro ao obter o token:', error);
      throw new Error('Erro ao obter o token de autenticação');
    }
  } else {
    throw new Error('Nenhum usuário autenticado');
  }
}
export async function obterTokenComClaims() {
  const auth = getAuth(); // Obtém a instância de autenticação
  const user = auth.currentUser; // Obtém o usuário autenticado atual

  if (user) {
    try {
      const token = await user.getIdTokenResult(); // Obtém o token do usuário
      return token;
    } catch (error) {
      console.error('Erro ao obter o token:', error);
      throw new Error('Erro ao obter o token de autenticação');
    }
  } else {
    throw new Error('Nenhum usuário autenticado');
  }
}
export async function deletarPostagemGlobal(postId){
  try { 
    
    const token = await obterToken();
    const response = await fetch(`${apiUrl}/deletarPostagem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ postId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }
    const data = await response.json();
    return data; // Dados do post
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function fetchAndSetPostagens(userId, tipo, offset, limit, userCorrenteUID,limitacao) {
  /*
  Aqui você quer pegar as postagens, ou de alguém, ou de todo mundo.
  Parâmetros:
    Caso seja postagens de um usuario específico, tipo perfil:
       user -> Vc quer pegar as postagens desse usuario
       tipo -> specificuser(postagens desse usuario em específico)
       offset -> offset das postagens q vai buscar
       limit -> qtdd de postagens q vai buscar
       userCorrenteUID -> Id do usuario logado pra conferir se ele curtiu ou não a postagem
    Caso seja todas as postagens:
       user -> nulo
       tipo -> everypost(todas postagens)
       offset -> offset das postagens q vai buscar
       limit -> qtdd de postagens q vai buscar
       userCorrenteUID -> Id do usuario logado pra conferir se ele curtiu ou não a postagem

    Além disso: tipo pode ser denuncia para postagens denunciadas e liberarGlobal para postagens para ser liberadas
    Parâmetro opcional:
       limitacao -> 'posts' ou 'shorts', serve pra selecionar se vc qr postagens de videos (shorts) ou de imgs (posts)
  */
      const token = await obterToken();
       try {
        
        const response = await fetch(`${apiUrl}/postagens`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            },
            body: JSON.stringify({
                userId,
                tipo,
                offset,
                limit,
                userCorrenteUID,
                limitacao,
            }),
        });
        console.log("requisicao feit");
        if (!response.ok) {
            console.error('Resposta da rede não foi ok', response.statusText);
            throw new Error('Network response was not ok');
        }
    
        const postagensArray = await response.json();
        return postagensArray;
    } catch (error) {
        console.error('Erro ao buscar postagens:', error);
    }
}
export async function deleteComment(comentarioId){
  const token = await obterToken();
  try {
    
      const response = await fetch(`${apiUrl}/deleteComment`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              comentarioId,
          }),
      });
  
      if (!response.ok) {
          console.error('Resposta da rede não foi ok');
          console.log(response);
          throw new Error('Resposta não foi n200',response);
      }
  
      const postagensArray = await response.json();
      return postagensArray;
  } catch (error) {
       throw error
  }
}
export async function verificaSeSegue(userIdSeguidor,userIdSeguido) { 
  try { 
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/verificaSeSegue`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ userIdSeguidor,userIdSeguido })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function obterQtdSeguidores(userId) {//só qtd seguidores 
  try { 
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/obterQtdSeguidores`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function obterQtdPostagens(userId) { //Só qtd postagens
  try { 
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/obterQtdPostagens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function obterQtdSegPosts(userId){ //qtdPostagens, qtd seguidores e qtd seguindo
  try { 
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/obterQtdSegPosts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function handleSeguirOuNao(userIdDonoPerfil){ //Id de quem vc qr seguir ou desseguir
  console.log(userIdDonoPerfil);
  try { 
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/handleSeguirOuNao`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({userIdDonoPerfil })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function getImageProfile(userId){ //Pegar imagem no mysql
  try { 
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/getImageProfile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function getImageProfileArray(someArray){ //Pegar imagens de perfil no sql, aceita array q possui .uid na primeira camada
  //profileImagePath vai ser anexado no array.
  try { 
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/getImageProfileArray`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ someArray })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}
export async function changeImageProfile(file){
  try { 
    const formData = new FormData();
    formData.append('imagePath', file); // Adiciona o arquivo ao FormData
    const token = await obterToken();
    
    const response = await fetch(`${apiUrl}/changeImageProfile`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    const data = await response.json();
    return data; // Dados 
  } catch (error) {
    throw new Error(error.message || 'Erro desconhecido');
  }
}


import style from "./index.module.css";
import { useState, useEffect } from "react";
import { getUser } from "../../../global";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ShowUserLittleProfile from "../../ShowUserLittleProfile";

export default function SearchModal({searchModalHandler,setSearchModalHandler,onClickSearchModal}) {
    const [searchInput, setSearchInput] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false); // Para controlar o estado de carregamento
  
    const searchUsers = async () => {
      if (!searchInput.trim()) {
        setResults([]);
        return;
      }
  
      setResults([]); //Tira do null pq fez pesquisa nova
      setLoading(true); // Inicia o carregamento
  
      try {
        const result = await getUser("search",searchInput);
        if(result){
          console.log(result);

          // Chega como obj, converter pra array
          const usuariosArray = Object.values(result)
          .filter(item => typeof item === 'object' && item.uid); // Filtra apenas os objetos de usuários, ignora o claim

          setResults(usuariosArray); 
        }else{ //N tem nada, colocar o useState como null pra aparecer q n tem resultado
          setResults(null);
        }
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        searchUsers();
      }
    };

    useEffect(() => { //Ao voltar no celular, fecha o modal em vez de ir pra página anterior
      
      window.history.pushState(null, '', window.location.href);// Adiciona uma entrada ao histórico quando o modal é aberto  
      
      const handlePopState = () => {
        onClickSearchModal();
      };
  
      window.addEventListener('popstate', handlePopState);
  
      // Limpeza do listener ao desmontar o modal
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, [searchModalHandler]); //Handler como dependencia (pois é ao abrir e ao fechar modal)


  
    return (
      <div className={style.searchModal}>
        <span className={style.iconVoltar}><AiOutlineArrowLeft size={24} onClick={()=>onClickSearchModal()}/></span>
        <p className={style.searchModalTitle}>Encontrar</p>
        <span className={style.searchModalContainer}>
          <input
            className={style.searchModalInput}
            onKeyDown={handleKeyDown}
            type="text"
            placeholder="Nome ou username"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button className={style.searchModalButton} onClick={searchUsers}>Procurar</button>
        </span>
        <div className={style.results}>
          {loading && <p>Carregando...</p>} {/* Mensagem de carregamento */}
          {results && results.length > 0 && results.map((user, index) => (
            <span key={index}>
              <ShowUserLittleProfile user={user} limiteCaracteres={20} />
            </span>
          ))}
          {results===null && !loading && <p>Nenhum usuário encontrado</p>}
        </div>
      </div>
    );
}